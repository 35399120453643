export const config = {
    RPC_URL: 'https://rpc-juno.itastakers.com',
    REST_URL: 'https://lcd-juno.itastakers.com',
    EXPLORER_URL: 'https://www.mintscan.io/juno',
    STAKING_URL: 'https://juno.omniflix.co/stake',
    NETWORK_NAME: 'Juno',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'juno-1',
    CHAIN_NAME: 'Juno',
    COIN_DENOM: 'JUNO',
    COIN_MINIMAL_DENOM: 'ujuno',
    COIN_DECIMALS: 6,
    PREFIX: 'juno',
    COIN_TYPE: 118,
    COSMOSTAION: 'juno',
    COINGECKO_ID: 'juno-network',
    DEFAULT_GAS: 200000,
    GAS_PRICE_STEP_LOW: 0.001,
    GAS_PRICE_STEP_AVERAGE: 0.0025,
    GAS_PRICE_STEP_HIGH: 0.004,
    FEATURES: ['ibc-transfer', 'cosmwasm', 'ibc-go'],
};
